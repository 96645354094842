$themes: (
  light: (
    backgroundColor: #f8f9fa,
    textColor: #f99f1d,
    borderColor: #f99f1d,
    navBackgroundColor: #eee,
    envTextColor: #7a736d,
    dangerTextColor: #fa0a0a,
    buttonBackgroundColor: transparent,
    hoverDangerTextColor: #af5b5e,
    hoverButtonBackgroundColor: transparent,
    inputBackgroundColor: #fff,
    inputTextColor: #000,
    inputLabelTextColor: #999,
    invertImagePercent: 0%,
    jsonStringColor: #006000,
  ),
  dark: (
    backgroundColor: rgb(46, 45, 45),
    textColor: #ddd,
    borderColor: #f99f1d,
    navBackgroundColor: #888,
    envTextColor: #f0f0f0,
    dangerTextColor: #ffffff,
    buttonBackgroundColor: #fa0a0a,
    hoverDangerTextColor: #af5b5e,
    hoverButtonBackgroundColor: #fff,
    inputBackgroundColor: rgb(46, 46, 46),
    inputTextColor: #fdeace,
    inputLabelTextColor: #eee,
    invertImagePercent: 100%,
    jsonStringColor: rgb(45, 248, 27),
  ),
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }

  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

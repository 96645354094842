@import "_typography";
@import "_reset";
@import "themes";

/* FLEXBOX-BASED LAYOUT */
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.container {
	display: flex;
	flex: 1;
}
main {
	flex: 1;
	padding: 0 20px;
}
nav {
	flex: 0 0 180px;
	padding: 0 10px;
	order: -1;
}
aside {
	flex: 0 0 130px;
	padding: 0 10px;
}

button {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font-size: 100%;
	vertical-align: baseline;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	-webkit-appearance: none;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: 0;
	cursor: pointer;
}

.main {
	@include themify($themes) {
		background-color: themed('backgroundColor');
    }
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor-readonly, div.jsoneditor-default {
	@include themify($themes) {
		color: themed('inputTextColor');
	}
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor-readonly, div.jsoneditor-default {
	&:hover, &:focus {
		@include themify($themes) {
			background-color: themed('backgroundColor') !important;
		}
	}	
}

div.jsoneditor-value.jsoneditor-string {
	@include themify($themes) {
		color: themed('jsonStringColor');
    }
}

textarea.jsoneditor-text {
	@include themify($themes) {
		background-color: themed('backgroundColor');
		color: themed('jsonStringColor');
    }
}
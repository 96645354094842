@import "../styles/themes";

.env-select {
    position: relative;
}

.env-select select {
	margin: 0.5em;
    padding: 0.1em 0.5em 0.1em 0.5em;
    border-radius: 0.5em;
    font-size: 11pt;
    outline: 0;
    @include themify($themes) {
        border: 0.06em solid themed('borderColor');
        background-color: themed('inputBackgroundColor');
        color: themed('inputTextColor');
    } 
}

.env-select label {
    position: absolute;
    margin-left: 1.5em;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, -0.01em) scale(1);
    pointer-events: none;
    transition: all .3s ease-out;
    font-size: 10pt;
    @include themify($themes) {
        color: themed('inputLabelTextColor');
    }    
}

.env-select select:focus + input label,
.env-select select:active + input label,
.env-select input:not([value=""]) + label {
    margin-left: 1em;
    transform: translate(0, -1.3em) scale(.85);
    @include themify($themes) {
        color: themed('textColor');
    } 
}

.env-error select {
    @include themify($themes) {
        border-color: themed('dangerTextColor');
    }  
}

.env-select span.message {
    position: absolute;
    margin-left: 1.5em;
    margin-top: 2em;
    top: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: all .3s ease-out;
    font-size: 8pt;
    @include themify($themes) {
        color: themed('dangerTextColor');
    }  
}

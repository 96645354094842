body, html {
    height: 100%;
}

#root {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.loginForm img {
    width: 400px;
    margin: 3em;
}

.loginForm h1 {
    text-shadow: 0.05em 0.05em 0 rgba(128, 128, 128, 0.445);
}

.loginForm img.google {
    width: 40px;
    margin: 0.5em;
}

.loginForm button {
    padding-right: 0em;
    width: 300px;
    margin-right: 0.5em;
	text-decoration: none;
    border: 0.06em solid transparent;
    border-radius: 2em;
    background-color: #f8f9fa;
    box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(128, 128, 128, 0.445);
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
}

.loginBox {
    display: flex;
    align-items: center;
    flex-direction: column;
}
@import "../styles/themes";

.orange {
    @include themify($themes) {
		color: themed('textColor');
    }
}

.jenkinsLogo {
    width: 25px;
    height: auto
}
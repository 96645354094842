.new-env {
    padding: 0.5em 0.8em;
    margin: 1em 2em 2em;
	text-decoration: none;
    border: 0.06em solid #f99f1d;
    border-radius: 0.5em;
    color: #f99f1d;
    box-shadow: 0.05em 0.05em rgba(128, 128, 128, 0.445);
}

.new-env button {
    padding: 0.2em 0.8em;
	text-decoration: none;
    border: 0.06em solid transparent;
    border-radius: 2em;
    background-color: #7a736d;
    color: white;
    box-shadow: 0.05em 0.1em rgba(128, 128, 128, 0.445);
    cursor: pointer;
}

.new-env button:active {
    box-shadow: 0.2em 0.2em rgba(128, 128, 128, 0.445);
}

.new-env button:hover {
    border-color: white;
}

.new-env .destroy {
	display: none;
    color: #fa0a0a;
    font-weight: bold;
    transition: color 0.2s ease-out;
    margin-left: 0.05em;
    background-color: transparent;
}

.new-env .destroy:hover {
    color: #af5b5e;
}

.new-env .destroy:after {
	content: '×';
}

.new-env .view:hover .destroy {
	display: inline;
}

.new-env .view {
    padding: 0.5em 0.8em;
    margin: 1em;
	text-decoration: none;
    border: 0.06em solid #f99f1d;
    border-radius: 0.5em;
}

.new-env .view .field {
    margin-top: 1em;
    margin-bottom: 1em;
}

.new-env .view #fullName {
    margin-bottom: 0.5em;
}

.new-env .clone i {
    font-size: 10pt;
}

.new-env .env-separator {
    margin: 1em;
}

.new-env .env-duplicated {
    color: red;
    animation-name: blinker;
    animation-duration: 1s;
    animation-direction:linear;
    animation-iteration-count: 2;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
@import "../styles/themes";

.header {
    flex-wrap: wrap;
}
.header img {
    width: 150px;
    height: 50px;
    margin: 0 3em;
}

.header img.profile {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 0;
}

.header h3 {
    color: #7a736d;
    margin: 0 1em;
}

.header button {
    cursor: pointer;
}

.header .email {
    font-size: 10pt;
}

.header .session {
    margin-left: 8em;
}

.App__theme-switcher button {
    margin: 0em 0.2em 0.3em;
    font-size: 0.8em;
    border-radius: 20%;
    padding: 0.3em;
    @include themify($themes) {
        border: 0.05em solid themed('borderColor');
    }   

    &#dark {
        background-color: black;
        border-color: black;
    }
}
@import "../styles/themes";

.env-list {
    display: flex;
    flex-wrap: wrap;
    vertical-align: center;
    margin: 0 2em;
    border-radius: 0.5em;
    @include themify($themes) {
        border: 0.06em solid themed('borderColor');
    }    
    box-shadow: 0.1em 0.1em rgba(128, 128, 128, 0.445);
    list-style: none;
    padding: 1em;
}

.env-list button.env {
    padding: 0.5em 0.8em;
    margin-right: 1em;
	text-decoration: none;
    border: 0.06em solid transparent;
    border-radius: 2em;
    color: white;
    box-shadow: 0.05em 0.1em rgba(128, 128, 128, 0.445);
}

.env-list li.env {
    @include themify($themes) {
        border: 0.06em solid themed('borderColor');
        color: themed('envTextColor');
    } 
    margin: 0.5em;
    padding: 0.5em;
    flex-basis: 40em;
}

.env-list li.env a {
    @include themify($themes) {
        color: themed('textColor');
    } 
}

.smallLogo {
    width: 20px;
    height: 20px;
    margin-left: 0.2em;
    margin-right: 5px;
}

.editLogoLabel {
    margin-right: 0.5em;
}

.env-list li .destroy {
    display: none;
    font-weight: bold;
    transition: color 0.2s ease-out;
    margin-left: 0.5em;
    display: inline;
    @include themify($themes) {
        color: themed('dangerTextColor');
        background-color: themed('buttonBackgroundColor');
    } 
}

.env-list li .destroy:hover {
    @include themify($themes) {
        color: themed('hoverDangerTextColor');
        background-color: themed('hoverButtonBackgroundColor');
    } 
}

.env-list li .destroy:after {
	content: '×';
}

.env-list .counter {
    margin-left: -0.5em;
    margin-top: 1em;
    padding: 1em;
    font-size: 100%;
    text-transform: capitalize;
    font-weight: bold;
    flex-basis: 100%;
}

span.audit {
    font-size: 10px;
    flex-shrink: 1;
    flex-wrap: wrap;
    flex-direction: row;
}

.env-list .view {
    display: flex;
}

.env-list .data {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 0.8 0 1em;
}

.env-list .jenkins {
    display: block;
    flex-basis: 100%;
}

.env-list .destroyView {
    flex-direction: row;
    align-self: center;
    margin-left: 2em;
}
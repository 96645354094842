@import "../styles/themes";

.filters {
    list-style: none;
    margin: 0em 0 0.5em;
    text-align: center;
    flex-wrap: wrap;
}

.filters li {
    display: inline;
    margin: 0.5em 0.2em;

}

.filters li span.filter {
	text-decoration: none;
    border: 0.06em solid transparent;
    border-radius: 2em;
    padding: 0.5em 0.8em;
    @include themify($themes) {
        background-color: themed('borderColor');
        color: white;
    }    
}

.filters li span.filter span {
    cursor: pointer;
}

.filters li span.actions i {
    padding: 0.1em;
    font-size: 18px;
    vertical-align: middle;
}

.filters li span:hover {
	border-color: white;
}

.filters li .destroy {
	display: none;
    color: #fa0a0a;
    font-weight: bold;
    transition: color 0.2s ease-out;
    margin-left: 0.5em;
}

.filters li .destroy:hover {
	color: #af5b5e;
}

.filters li .destroy:after {
	content: '×';
}

.filters li:hover .destroy {
	display: inline;
}
@import "themes";

/* RESET LAYOUT AND ADD COLORS */
body {
	margin: 0;
	padding: 0;
    max-width: inherit;
    @include themify($themes) {
        background: themed('backgroundColor');
        color: themed('textColor');
    }
}
header, footer {
	font-size: large;
	text-align: center;
	padding: 0.3em 0;
    @include themify($themes) {
        background: themed('backgroundColor');
        color: themed('textColor');
    }
}
nav {
    @include themify($themes) {
        background: themed('navBackgroundColor');
    }
}
main {
	@include themify($themes) {
        background: themed('backgroundColor');
    }
	font-size: 100px;
}
aside {
    @include themify($themes) {
        background: themed('navBackgroundColor');
    }
}

.jenkins {
    display: flex;
    flex-direction: column;
}
.jenkins button.jenkins-button {
    padding: 0.2em 0.8em;
	text-decoration: none;
    border: 0.06em solid transparent;
    border-radius: 2em;
    background-color: #7a736d;
    color: white;
    box-shadow: 0.05em 0.1em rgba(128, 128, 128, 0.445);
}

.jenkins button.jenkinsCancel {
    color: #7a736d;
    background-color: white;
}

.jenkins .buttons {
    margin: 1em;
    text-align: center;
}

.jenkins .jsoneditor {
    height: 700px;
}